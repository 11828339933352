import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/authentication/authContext';

import { getReportsForSubscription } from 'utils/api/reports';
import { setDataOrErrorMessage } from 'utils/hooks/useGetReports';

export const ReportContext = React.createContext();

export const ReportProvider = ({ children }) => {
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);
  const [reports, setReports] = useState([]);
  const [finished, setFinished] = useState(false);
  const [errorMessageReports, setErrorMessage] = useState('');

  const { accessToken, loading } = useContext(AuthContext);


  useEffect(() => {
    const response = async () => {
      if(currentSubscriptionId) {
        const { response, data, error } = await getReportsForSubscription(
          currentSubscriptionId,
          accessToken,
        );

        setDataOrErrorMessage(
          response,
          data,
          error,
          setReports,
          setErrorMessage,
          setFinished,
        );
      }
    };
  
    if (!loading) {
      response();
    }
  }, [currentSubscriptionId, accessToken, loading]);
  
  const store = {
    currentSubscriptionId,
    setCurrentSubscriptionId,
    setReports,
    reports,
    errorMessageReports,
    finished
  };

  return (
    <ReportContext.Provider value={store}>
      {children}
    </ReportContext.Provider>
  );
};

export const ReportContextConsumer = ReportContext.Consumer;
