import React from "react";
import { Route, Switch } from "react-router-dom";
import IdleTimeOutHandler from 'components/idleTimeoutHandler/idleTimeoutHandler';
import { LOGIN_PATH, LOGOUT_PATH } from 'utils/configuration/links';
import { AuthContext } from 'contexts/authentication/authContext';
import { AuthHandler } from 'contexts/authentication/authContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from "containers/app/app";
import { Auth0State } from "state";
import { ReportProvider } from "contexts/reportContext";

const SecurityBoundary = () => {
  const { organizationAlias } = React.useContext(AuthContext);
  const queryClient = new QueryClient();
  
  const auth0State = Auth0State();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <IdleTimeOutHandler auth0State={auth0State}></IdleTimeOutHandler>
        <Switch>
          <Route exact path={`/${organizationAlias}/${LOGIN_PATH}`}>
            <div>Logging in...</div>
          </Route>
          <Route exact path={`/${organizationAlias}/${LOGOUT_PATH}`}>
            <div>Logging out...</div>
          </Route>
          <Route path={"/"}>
            <ReportProvider>
              <App />
            </ReportProvider>
          </Route>
        </Switch>
        <AuthHandler />
      </QueryClientProvider>
    </>
  );
}

export default SecurityBoundary;