import React from 'react';

import { Switch, Route } from "react-router-dom";

import ReportEmbed from 'components/reports/reportEmbed/reportEmbed';
import SideNav from 'components/sideNav/sideNav';
import Header from "components/header/header";
import Footer from "components/footer/footer";
import { AuthContext } from 'contexts/authentication/authContext';
import { REPORT_SELECTED_PATH } from 'utils/configuration/links';
import { useGetSubscriptions } from 'utils/hooks/useGetSubscriptions';

import style from "./app.module.scss"
import { ReportContext } from 'contexts/reportContext';


const ReportsContainer = () => {
  const [reportRendered, setReportRendered] = React.useState();
  const reportContainerRef = React.createRef();
  const subscriptions = useGetSubscriptions();
  const { signedIn, user, organizationAlias } = React.useContext(AuthContext);

  const { reports: reportsData, finished: reportsFinished, errorMessageReports } = React.useContext(ReportContext);

  return (
    <div className={style.appContainer}>
        <Header user={user} signedIn={signedIn} className={style.headerContainer} />      
        <SideNav className={style.sideNavContainer} reportRendered={reportRendered} reportContainerRef={reportContainerRef} subscriptions={subscriptions} />
        <main id="reportContainer" className={style.reportContainer}>
              <Switch>
                <Route path={`/${organizationAlias}/${REPORT_SELECTED_PATH}`} render={routeProps => {
                  return (<ReportEmbed {...routeProps} setReportRendered={setReportRendered} ref={reportContainerRef} />);
                }}/>
                <Route>
                  <div className={style.blankReports}>
                    {!reportsFinished || !subscriptions?.finished 
                    ? <p>Loading reports and subscriptions, please wait...</p>
                    : (errorMessageReports || subscriptions?.errorMessageSubs || (reportsFinished && reportsData.length === 0) || (subscriptions?.finished && subscriptions?.data.length === 0) ? 
                      <p>The system was unable to load any reports at this time. Please refresh the page to try again or contact your Healthwise administrator.</p> :
                      <p>Please select a report from the header and a subscription from the menu on the left.</p>)
                    }
                  </div>
                </Route>
              </Switch>
        </main>
    <Footer />
    </div>
  )
}

export default ReportsContainer;