import React from 'react';
import {  NavLink, generatePath } from 'react-router-dom';
import { IconButton } from 'components/buttons/buttons';
import { KeyboardUpArrowIcon, KeyboardDownArrowIcon } from 'components/icons/icons';
import { SUBSCRIPTIONS_SELECTED_PATH } from 'utils/configuration/links';

import style from './subscriptionDropdown.module.scss';
import useMenuManageFocus from 'utils/hooks/useMenuManageFocus';
import { AuthContext } from 'contexts/authentication/authContext';

const selectSubscription = (newSelectedValue, visible, setSelectedSubscription, setVisible, match) => {
    setSelectedSubscription(newSelectedValue.id, match);
    if(visible) {
      setVisible(false);
    }
}

const SubscriptionDropdown = ({setSelectedSubscription, data, selectedSubscriptionId, match}) => {
  const liArrayRef = React.useRef([]);
  const navRef = React.useRef(null);
  const btnRef = React.useRef(null);
  const { organizationAlias } = React.useContext(AuthContext);

  const {visible, setVisible} = useMenuManageFocus(data.length, liArrayRef, navRef, btnRef);

  const handleClick = () =>{
      setVisible(!visible);
  }

  const handleSubscriptionClick = (newSelectedValue) => {
    selectSubscription(newSelectedValue, visible, setSelectedSubscription, setVisible, match);
  }

  const renderFullDropdown = () => {
    return (
      <nav ref={navRef} aria-label="subscriptions">
        <IconButton ref={btnRef} ariaLabel="subscription dropdown" ariaControls="subDropdownMenu" ariaHaspopup="menu" ariaExpanded={visible}  className={style.iconButton} handleClick={handleClick}>
            { selectedValue ? selectedValue.name : 'Select...' }
              { visible ? <KeyboardUpArrowIcon className={style.downArrowIcon} /> : <KeyboardDownArrowIcon className={style.downArrowIcon} /> }
        </IconButton>
        <ul role="menu" id="subDropdownMenu" className={`${style.list} ${visible ? "" : style.invisible}`}>
          {data.map((tab) => {
            return(
              <li role="presentation" key={tab.id}>
                <NavLink
                  role="menuitem"
                  ref={(ref)=> liArrayRef.current.push(ref)}  
                  to={{
                    pathname: generatePath(`/${organizationAlias}/${SUBSCRIPTIONS_SELECTED_PATH}`, {subscriptionId: tab.id}),
                    search: window.location.search
                  }} 
                  onClick={() => handleSubscriptionClick(tab)} 
                  className={selectedValue?.id === tab.id ? style.selectedButton : ''}
                >
                  {tab.name}
                </NavLink>
              </li>
            )}
          )}
        </ul>
      </nav>);
  }

  const renderComponent = () => {
    switch (data.length) {
      case 0:
        return (<div className={style.noData}>No subscriptions available</div>);
      case 1:
        return null;
      default:
        return renderFullDropdown();
     }
  }

  const selectedValue = data.find(x => x.id === selectedSubscriptionId);

  return renderComponent();
}

export default SubscriptionDropdown;